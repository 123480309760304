<template>
  <el-dialog
    v-model="dialogVisible"
    class="download-arvin-dialog1 relative w-386px! m-18px! md:m-auto! p-0! md:p-32px top-[calc(50vh-281px)] md:top-15vh rounded-24px!"
    :close-on-click-modal="false" destroy
    :before-close="handleClose">
    <div
      id="bootDownloadGroupTestA"
      class="relative rounded-24px test-A text-left p-24px pb-0">
      <img src="~/assets/imgs/download-test.png" class="w-full mb-24px pointer-events-none rounded-12px">
      <h2 class="mb-16px fs-24 title">Experience Next-Gen AI on Any Website</h2>
      <div class="fs-14 leading-17px c-deep-80 font-500">
        <p class="mb-8px">· Become logo designer, tattoo designer, etc.</p>
        <p class="mb-8px">· <span class="c-primary">4000+ prompts</span> to revolutionize your work!</p>
        <p>· Plugin and webapp, support <span class="c-primary">all browsers and <span class="pl-8px">websites</span></span>.</p>
      </div>
      <div class="flex-between py-24px">
        <div
          :id="isDefiniteTime ? 'downLoadArvinOrGetFree' : 'downLoadArvinForClient'"
          class="primary-btn relative dialog-btn-full rounded-16px! font-700! fs-18!" @click="handleDownload">⚡️ Get
          Started Now
          <span class="absolute right-0 top--10px px-12px h-24px leading-24px fs-14 font-500 c-white">Try for
            Free</span>
        </div>
      </div>
    </div>
    <div
      id="bootDownloadClose"
      class="w-36px h-36px absolute left-50% m-l--18px bottom--60px bg-#B7B9C9 rounded-50% flex-center cp"
      @click="close">
      <SvgIcon name="close" size="16" class="c-deep-0">
      </SvgIcon>
    </div>
  </el-dialog>
</template>
<script setup lang="ts">
	const props = defineProps({
		modelValue: {
			type: Boolean,
			default: false
		},
		isDefiniteTime: {
			type: Boolean,
			default: false
		}
	});

	const route = useRoute();
	const isDesign = ref(false);

	if (route.path === '/chatgpt-prompt-generator/chatgpt-prompts-for-designers') {
		isDesign.value = true;
	}

	const emit = defineEmits(['update:modelValue']);
	const dialogVisible = useVModel(props, 'modelValue', emit);
	const close = () => {
		dialogVisible.value = false;
	};
	const handleClose = (done: () => void) => {
		done();
		setStorage('showDownloadDialog', '1');
	};

	watch(() => dialogVisible.value, (v) => {
		if (v) {
			const eventName = isDesign ? 'download_auto_dialog_design' : 'download_auto_dialog';
			trackEvent('Dialog', 'show', eventName);
		}
	});

	function handleDownload () {
		const eventName = isDesign ? 'download_in_auto_dialog_design' : 'download_in_auto_dialog';
		trackEvent('Button', 'click', eventName);
		// downloadArvin();
		window.location.href = '/en/home';
	}
</script>

<style lang="less">
.download-arvin-dialog1 {

	&.el-dialog {
		.el-dialog__header {
			height: 0;
			padding: 0;
			display: none;
		}

		.el-dialog__body {
			padding: 0;
		}
	}

	.dialog-btn {
		height: 46px;
		width: 196px;
		font-size: 16px;
		justify-content: center;
	}

	.dialog-btn-full {
		width: 100%;
		height: 58px;
		font-size: 16px;
		justify-content: center;
		background: linear-gradient(273deg, #6165F6 6%, #9D61F6 92%);
	}

	.test-A {
		.title {
			font-weight: bold;
			font-variation-settings: "opsz" auto;
			font-feature-settings: "kern" on;
			background: linear-gradient(90deg, #6165F6 0%, #D361EC 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
		}

		.dialog-btn-full span {
			background: linear-gradient(134deg, #FFC83B 8%, #FC8B00 46%);
			border-radius: 12px 12px 12px 0;
		}
	}
}
</style>
